
          $PRIMARY-COLOR: #006a9b;
          $AUTH-BG-GRADIENT-0: #59D1AE;
          $AUTH-BG-GRADIENT-100: #2CA581;
        

.page-loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 222px;
  transform: translate(-50%, -50%);

  .custom-progress {
    position: relative;
    height: 30px;
    border: 10px solid #d9d9d9;
    border-radius: 15px;
    background: #ffffff;

    .custom-progress-indicator {
      position: absolute;
      background-color: $PRIMARY-COLOR;
      width: 100%;
      height: 10px;
      border-radius: 15px;
      animation: progres 3s linear;
    }
  }
}

@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 85%;
  }
  75% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
