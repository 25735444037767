
          $PRIMARY-COLOR: #006a9b;
          $AUTH-BG-GRADIENT-0: #59D1AE;
          $AUTH-BG-GRADIENT-100: #2CA581;
        

.bg-container {
  overflow: hidden;
  padding-right: 0;
  background: none !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
